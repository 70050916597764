import React, { useState } from "react";

const FaqCard = ({faqData}: any) => {
    const [expandPanel, setExpandPanel] = useState<boolean>(false);

    const handleExpandPanel = () => {
        setExpandPanel(!expandPanel)
    }

    return(
        <>
            <div className={`bg-white rounded-lg px-4 py-3 font-medium ring-2 ring-slate-200 transition duration-300 hover:ring-secondary w-full mb-4 ${expandPanel? 'ring-secondary':'ring-slate-200'}`}>
                <div className="flex text-sm justify-between items-center cursor-pointer" onClick={handleExpandPanel}>
                    <p>{faqData?.question}</p>
                    <div className={`${expandPanel? 'rotate-180':''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                </div>
                {expandPanel && 
                    <div className="p-3 my-4 text-sm rounded-lg bg-slate-100 text-gray-600">
                        <p>{faqData?.answer}</p>
                    </div>
                }
            </div>
        </>
    )
}

export default FaqCard;