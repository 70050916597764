import React, { useEffect, useState } from "react";
import { AppService } from "../../services/AppService";
import NoDataFoundImg from "../../assets/images/no-data-found.png"

const UserRequests = () => { 
    const [requestList, setRequestList] = useState([]); 

    useEffect(() => {
        fetchRequtest();
    },[])

    async function fetchRequtest() {
        try {
            const response = await AppService.fetchRequestList();
            if(response?.status == 200){
               setRequestList(response?.data?.data|| []);
            }else{
                setRequestList([]);
            }
        } catch (error) {
          console.error('Error fetching products:', error);
        }
    }

    return(
        <>
        <section className="p-6 py-[20px] w-full flex justify-center">
                <div className="max-w-[1400px] w-full px-2 md:px-10 flex flex-col">
                    <div className="w-full flex flex-col items-center justify-center">
                        <h2 className="font-bold text-center text-[30px]">Requested Product List </h2>
                        <div className="border-b-[6px] border-secondary w-[30px] mb-5"></div>
                    </div>
                    <div className="flex flex-row">
                    { requestList?.length > 0 ? 
                    <div className="w-full mb-10 min-h-[300px]">
                        <div className="w-full flex flex-wrap">
                            {requestList?.map((request: any, index: any) => (
                                <div className="w-full p-3 ring-1 mb-3 rounded-lg ring-slate-200 flex justify-between" key={index}>
                                    <div>
                                        <p className="text-sm">Product Name:</p>
                                        <p className="font-semibold">{request?.product_name}</p>
                                    </div>
                                    <div>
                                    { request?.status === 1 && (
                                        <div className="px-3 py-[4px] bg-amber-100 text-amber-500 text-sm font-semibold w-fit rounded-md">PENDING</div>
                                    )}
                                    { (request?.status === 6) && (
                                        <div className="px-3 py-[4px] bg-green-100 text-green-500 text-sm font-semibold w-fit rounded-md">ACCEPTED</div>
                                    )}
                                    { (request?.status === 4) && (
                                        <div className="px-3 py-[4px] bg-red-100 text-red-500 text-sm font-semibold w-fit rounded-md">REJECTED</div>
                                    )}
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div> :
                    <div className="w-full flex flex-col justify-center items-center py-20">
                        <img className="w-[100px]" src={NoDataFoundImg} alt="NoDataFoundImg" />
                        <h2 className="text-black font-bold text-center text-[30px] mb-2">Data Not Found!</h2>
                        <p className="text-slate-500 font-medium text-center text-[16px] w-full md:w-[700px] mb-3">We couldn't find any data for request list or there may be an issue.</p>
                    </div>
                    }
                    </div>
                </div>
            </section>
        </>
    )
}

export default UserRequests;