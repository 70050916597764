
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        

<footer className="bg-gray-100 border-t-2 border-gray-200">
    <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
            <div>
                <Link to={'/'} className="flex items-center mb-4">
                    <div className="text-gray-600"><span className='text-[28px] font-[700]'>Meridian</span><span className='text-[26px]'>guru</span></div>
                </Link>
                <div>
                    <div id="google_translate_element"></div>
                    <p className="mt-[-8px]">Google</p>
                </div>
            </div>
           <div>
                <ul className="flex flex-wrap items-center mb-6 text-md font-medium text-gray-700 sm:mb-0">
                    <li>
                        <Link to="/about" className="hover:underline me-4 md:me-6">About</Link>
                    </li>
                    <li>
                        <Link to="/privacy-policy" className="hover:underline me-4 md:me-6">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to="/contact" className="hover:underline">Contact Us</Link>
                    </li>
                </ul>
           </div>
        </div>
        <hr className="my-6 border-gray-400 sm:mx-auto dark:border-gray-100 lg:my-8" />
        <span className="block text-sm text-gray-600 sm:text-center dark:text-gray-400">© 2024 <Link to="/home" className="hover:underline">MeridianGuru</Link>. All Rights Reserved.</span>
    </div>
</footer>


    )
}

export default Footer;