import React, { useEffect, useState } from "react";

interface PaginationProps {
    totalCount: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    pageNo: number;
}

const Pagination: React.FC<PaginationProps> = ({ totalCount, pageSize, onPageChange, pageNo }) => {
    const [currentPage, setCurrentPage] = useState<number>(pageNo);
    const [pageNumberArray, setPageNumberArray] = useState<number[]>([]);
    const totalPages = Math.ceil(totalCount / pageSize);
    const startResult = (currentPage - 1) * pageSize + 1;
    const endResult = Math.min(currentPage * pageSize, totalCount);

    useEffect(() => {
        setCurrentPage(pageNo);
    }, [pageNo])

    useEffect(() => {
        handlePageArray();
    }, [currentPage, startResult, endResult]);

    const handlePageArray = () => {
        let array = [];
        const maxPagesToShow = 5;
        let startPage = currentPage - Math.floor(maxPagesToShow / 2);
        let endPage = currentPage + Math.floor(maxPagesToShow / 2);
    
        if (startPage <= 0) {
            startPage = 1;
            endPage = Math.min(totalPages, maxPagesToShow);
        } else if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - maxPagesToShow + 1);
        }
    
        for (let i = startPage; i < endPage; i++) {
            array.push(i);
        }
    
        setPageNumberArray(array);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        onPageChange(page);
    };

    const handleNextButton = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            onPageChange(currentPage + 1);
        }
    };

    const handlePreviousButton = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            onPageChange(currentPage - 1);
        }
    };

    return (
        <>
            <div className="my-5 flex flex-col md:flex-row items-center sm:justify-between">
                <div className="mb-3 md:mb-0">
                    {(totalCount > 0) && <p className="text-sm text-gray-600">
                        Showing <span className="font-semibold text-gray-800">{startResult}</span>  to  <span className="font-semibold text-gray-800"> {endResult} </span>  of <span className="font-semibold text-gray-800">{totalCount}</span>  results
                    </p>}
                </div>
                <div>
                   { (pageNumberArray?.length > 0) &&
                    (
                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <div onClick={handlePreviousButton} className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>
                            { ((currentPage >= (totalPages - 1)) && totalPages > 5) &&
                                (<>
                                    <div onClick={() => handlePageChange(1)} className={`relative inline-flex cursor-pointer items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${1 === currentPage ? 'bg-slate-100 text-primary ease-in transition-colors duration-300' : 'transition-colors duration-300 hover:bg-gray-50'}`}>1</div>
                                    <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                                </>)
                            }
                            {pageNumberArray.map((page, index) => (
                                <div key={index} onClick={() => handlePageChange(page)} className={`relative inline-flex cursor-pointer items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${page === currentPage ? 'bg-slate-100 text-primary transition-colors duration-300' : 'transition-colors duration-300 hover:bg-gray-50'}`}>{page}</div>
                            ))}
                            { (currentPage <= (totalPages - 2)  && totalPages > 5) &&
                                (<span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>)
                            }
                            <div onClick={() => handlePageChange(totalPages)} className={`relative inline-flex cursor-pointer items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${totalPages === currentPage ? 'bg-slate-100 text-primary transition-colors duration-300' : 'transition-colors duration-300 hover:bg-gray-50'}`}>{totalPages}</div>
                            <div onClick={handleNextButton} className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </nav>
                    )
                   }
                </div>
            </div>
        </>
    );
};

export default Pagination;
