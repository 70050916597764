import http from "../configuration/axios";

export class AuthService {
    static async login(data: any) {
        try {
            return (await http.post(`oauth/login`, data));
        } catch (error) {
            throw error;
        }
    }

    static async signup(data: any) {
        try {
            return (await http.post(`oauth/signup`, data));
        } catch (error) {
            throw error;
        }
    }

    static async getUserDetails() {
        try {
            return (await http.get(`api/userdetails`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }
}