import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductFilter from "./ProductFilter";
import { useAppContext } from "../../hooks/UseAppContext";
import Pagination from "../../ui-components/Pagination";
import Search from "../../ui-components/Search";
import NoDataFoundImg from "../../assets/images/no-data-found.png"
import ProductCard from "./ProductCard";
import Loader from "../../ui-components/Loader";

const ProductList = () => {
    const { selectedSubCategoriesForFilter, resetFilter, handleRemoveFilter, handelModuleId, productList, handlePageNoChange, totalCount, pageSize, handlePayStatusChange, handleSearch, loader } = useAppContext();
    const navigate = useNavigate();
    const [showFilter, setShowFilter] =  useState(false);
    const [payStatus, setPayStatus] = useState('');
    const [pageNo, setPageNo] = useState(1);
    let params = useParams(); 


    const handleSearchChange = (event: any) => {
        handlePageNoChange(1);
        setPageNo(1)
        handleSearch(event)
    };

    useEffect(() => {
        handlePageNoChange(1);
        setPageNo(1)
        handelModuleId(params.id);
    }, [params.id]);

    const handleFilter = (flag: any) => {
        setShowFilter(flag);
    }

    const handleProductClick = (id: any) => {
        const checkAccessToken = localStorage.getItem('access_token');
        if(checkAccessToken){
            navigate('/product-details/' + id);
        }else{
            navigate('/login?productId=' + id);
        }
        
    }

    const handlePageChange = (data: any) => {
        handlePageNoChange(data);
        setPageNo(data)
    }
    

    return(
        <>
            <section className="p-6 py-[20px] w-full flex justify-center">
                <div className="max-w-[1400px] w-full px-2 lg:px-10 flex flex-col">
                    <div className="flex flex-col md:flex-row md:justify-between mx-3">
                        <Search onSearch={handleSearchChange}/>
                        <div className="flex items-center mt-2 md:mt-0 justify-between">
                            <div className="form-control w-[150px] me-3">
                                <select name="paystatus" value={payStatus} onChange={(event) => { setPayStatus(event.target.value); handlePayStatusChange(event.target.value)}}>
                                    <option value="">All</option>
                                    <option value="1">Paid</option>
                                    <option value="2">Free</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <div onClick={ handleFilter } className="flex flex-row bg-white border border-[#dbdfe9] px-3 py-[5px] rounded-[8px] cursor-pointer items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                                    </svg>
                                    <p className="ms-2 mt-1">Filters</p>
                                    { (selectedSubCategoriesForFilter?.length > 0) && (<span className="bg-slate-600 text-white h-[20px] w-[20px] rounded-full text-xs flex items-center justify-center text-center ms-2">{selectedSubCategoriesForFilter?.length}</span>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap gap-3 mb-2 mx-3">
                            { selectedSubCategoriesForFilter?.map((subCate: any) => 
                                (<div className="bg-slate-100 ring-1 rounded-md ring-slate-200 text-slate-800 font-medium text-sm px-3 py-2 flex items-center"> 
                                    {subCate?.name} 
                                    <i onClick={() => handleRemoveFilter(subCate?.id)} className="text-[12px] font-bold hover:text-red-700 cursor-pointer ms-2 lni lni-close"></i>
                                </div>)
                            )}
                            { (selectedSubCategoriesForFilter?.length > 1) && (<button onClick={ resetFilter } type="button" className="bg-red-100 transition duration-150 hover:bg-red-200 rounded-md text-red-600 font-medium text-sm px-3 py-2"> Clear all </button>)}
                    </div>
                    <div className="flex flex-row">
                    { productList?.length > 0 ? 
                    <div className="w-full">
                        <div className="w-full flex flex-wrap">
                            {productList?.map((product: any, index: any) => (
                                <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-3" key={index}>
                                    <ProductCard product={product} onClick={(event: any) => handleProductClick(event)}/>
                                </div>
                            ))}
                            
                        </div>
                        <div className="m-3">
                            <Pagination pageNo={pageNo} totalCount={totalCount} pageSize={pageSize} onPageChange={handlePageChange}/>
                        </div>
                    </div> :
                    <div className="w-full flex flex-col justify-center items-center py-20">
                        <img className="w-[100px]" src={NoDataFoundImg} alt="NoDataFoundImg" />
                        <h2 className="text-black font-bold text-center text-[30px] mb-2">Data Not Found!</h2>
                        <p className="text-slate-500 font-medium text-center text-[16px] w-[700px] mb-3">We couldn't find any data matching your selected filters or there may be an issue.</p>
                    </div>
                    }
                    </div>
                </div>
            </section>
            { showFilter && <ProductFilter close={() => handleFilter(false)}/>}
            { loader && <Loader/>}
        </>
    )
};

export default ProductList;

