import http from "../configuration/axios";

export class AppService {
    static async fetchModules() {
        try {
            return (await http.get(`openapi/modules`));
        } catch (error) {
            throw error;
        }
    }

    static async fetchCategory(id: any) {
        try {
            return (await http.get(`openapi/category/${id}`));
        } catch (error) {
            throw error;
        }
    }

    static async fetchSubCategory() {
        try {
            return (await http.get(`openapi/subcategory`));
        } catch (error) {
            throw error;
        }
    }

    static async fetchProducts(moduleId: any, pageNo: any, pageSize: any, selectedSubCategoriesForFilter: any, payStatus?: any, searchTerm?: any) {
        try {
            let api = `openapi/products/${moduleId}`;
            
            if(pageSize && pageNo){
                api = api + `?pageSize=${pageSize}&pageNo=${pageNo}`
            }
            if(selectedSubCategoriesForFilter){
                api = api + `&subcategory=${selectedSubCategoriesForFilter}`
            }

            if(payStatus){
                api = api + `&payStatus=${payStatus}`
            }

            if(searchTerm){
                api = api + `&search=${searchTerm}`
            }

            return (await http.get(api));
        } catch (error) {
            throw error;
        }
    }

    static async fetchProductDetails(productId: any) {
        try {
            let api = `api/site/productdetails/${productId}`;
            return (await http.get(api, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async requestProduct(data: any) {
        try {
            let api = `api/product/request`;
            return (await http.post(api, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async submitEnquiry(data: any) {
        try {
            let api = `openapi/enquiry`;
            return (await http.post(api, data));
        } catch (error) {
            throw error;
        }
    }

    static async updateUser(data: any) {
        try {
            return (await http.put(`api/user`, data, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async fetchUserProductList() {
        try {
            let api = `api/site/userproductlits`;
            return (await http.get(api, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

    static async fetchRequestList() {
        try {
            let api = `api/site/userrequestedproductlits`;
            return (await http.get(api, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              }));
        } catch (error) {
            throw error;
        }
    }

}
