import React from "react";
import FaqCard from "./FaqCard";
import { faqList } from "../utils/constants";

const FaqSection = () => {
    const faqs: any = faqList;

    return(
        <div className="bg-white p-6 w-full my-[40px] flex justify-center">
            <div className="md:max-w-[1400px] w-full px-2 md:px-10 flex flex-col items-center">
                <div>
                    <h2 className="text-[26px] md:text-[36px] font-bold text-black text-center">Frequently Asked Questions</h2>
                    <div className="flex justify-center">
                        <div className="border-b-[6px] border-secondary w-[30px] mb-3"></div>
                    </div>
                    <p className="text-gray-600 hidden md:block font-medium text-center">
                        Explore our comprehensive list of frequently asked questions to  <br></br>
                        find detailed answers and helpful information about our services.
                    </p>
                    <p className="text-gray-600 md:hidden font-medium text-center">
                        Explore our comprehensive list of frequently asked questions to
                        find detailed answers and helpful information about our services.
                    </p>
                </div>
                <div className="flex flex-col mt-8 w-full lg:w-[800px]">
                    { faqs?.map((faq: any, index: any) => (
                        <FaqCard key={index} faqData={faq} />
                    ))}
                </div>
            </div>
        </div>
    )
};

export default FaqSection;