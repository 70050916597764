import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import Toaster from './ui-components/Toaster';
import { toast } from 'react-toastify';
import PageLoader from './ui-components/PageLoader';

function App() {

  useEffect(() => {
    const preventRightClick = (e: any) => {
      e.preventDefault();
    };

    const preventCopy = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      toast.warning('Copying content is not allowed!');
    };

    document.addEventListener('contextmenu', preventRightClick);
    document.addEventListener('copy', preventCopy);

    return () => {
      document.removeEventListener('contextmenu', preventRightClick);
      document.removeEventListener('copy', preventCopy);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <AppRoutes/>
        <PageLoader/>
      </BrowserRouter>
      <Toaster/>
    </>
  );
}

export default App;
