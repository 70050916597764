import React from 'react';

const Modal = ({ children }: any) => {
    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center">
                <div className="relative transform overflow-hidden rounded-[16px] bg-white text-left drop-shadow-2xl transition-all my-8 max-w-[95vw]">
                    {children}
                </div>
            </div>
        </div>
    </div>
    )
}

export default Modal;