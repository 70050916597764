export const messages: any = {
    error: "Something went wrong. Please try after sometime.",
    login: {
        invalidCreds: "Invalid username and password",
        userNotFound: "User details not found"
    },
    user: {
        createdSuccess: "User created successfully.",
        updatedSuccess: "User updated successfully.",
        deletedSuccess: "User deleted successfully.",
        revertedSuccess: "User reverted successfully.",
        alreadyExist: "Record already exist.",
        recordNotFound: "User not Found.",
        profileUpdateSuccess: "Profile updated successfully.",
        productRemovedSuccess: "Product removed successfully."
    },
    enquiry: {
        createdSuccess: "Enquiry submitted successfully.",
    },
    product: {
        requestSuccess: "Request submitted successfully.",
    }
}