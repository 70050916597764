
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { AppProvider } from "../../context/AppContext";
import { UserProvider } from "../../context/UserContext";

const Layout = ({ children }: any) => {

    return (
        <UserProvider>
        <AppProvider>
            <div className="overflow-hidden">
                <Header/>
                <div className="relative flex h-full max-w-full flex-1 flex-col mt-[77px]">
                    {children}
                </div>
                <Footer/>
            </div>
        </AppProvider>
        </UserProvider>
    )
}

export default Layout;