import React from "react";
import AppointmentBg from "../assets/images/3556960.jpg"

const AppoinmentSection = () => {
    return(
        <div className="bg-white p-6 w-full my-[40px] flex justify-center">
            <div className="max-w-[1400px] w-full px-5 md:px-10 flex flex-col md:flex-row bg-gradient-to-r from-white to-slate-50 h-full py-5 md:h-[350px] rounded-[25px] ring-2 ring-slate-200">
                <div className="md:me-8 hidden md:block">
                    <img src={AppointmentBg} alt="AppointmentBf" className="h-auto w-auto max-w-[100%] max-h-[100%]" />
                </div>
                <div className="md:ms-6 flex flex-col justify-center">
                    <h2 className="text-[22px] md:text-[32px] font-semibold md:font-bold text-[#0b80c7]">Join us on the journey to holistic wellness and take control of your health today.</h2>
                    <p className="text-md md:text-lg mt-4 text-gray-600 font-medium">Book your online appointment now and experience the transformative power of holistic healing with Anita Parwani's Alternative Therapy Solution.</p>
                    <div className="flex items-center space-x-3 mt-2 md:mt-6 mb-3 md:mb-1">
                        <a href="https://calendly.com/meridianguru" rel="noreferrer" target="_blank" className="text-[16px] bg-primary border-2 border-primary text-white rounded-md px-6 py-2 font-medium transition duration-300 hover:text-primary hover:bg-transparent transition duration-150 ease-in flex items-center">Make a Appoinment <i className="ms-3 lni lni-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppoinmentSection;