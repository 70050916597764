import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "./Loader";

export default function PageLoader() {
  const [loader, setLoader] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setLoader(true);
    const timer = setTimeout(() => {
        setLoader(false);
    }, 1000); // 3000 milliseconds (3 seconds)

    return () => clearTimeout(timer);
  }, [pathname]);

  return (
    <>
    {loader && <Loader/>}
    </>
  );
}