import React from "react";
import acupressure from '../../assets/images/acupressure.jpg'
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../hooks/UseAppContext";
import { environment } from "../../environment/environment";

const ModuleSection = () =>{
    const navigate = useNavigate();
    const { moduleList } = useAppContext();
    const baseUrl: any = environment.baseURL;

    const handleModuleClick = (id: any) => {
        navigate('/product/'+id);
    }

    return(
        <div className="bg-gray-50 module-bg p-2 md:p-6 py-[40px] w-full flex justify-center">
            <div className="max-w-[1400px] md:px-10 flex flex-col">
                <div>
                    <h1 className="text-black font-bold text-[30px] text-center">Modules</h1>
                    <div className="flex justify-center">
                        <div className="border-b-[6px] border-primary w-[30px] mb-3"></div>
                    </div>
                    <p className="text-gray-500 text-center">Discover our diverse range of holistic therapies designed to <br></br> rejuvenate your mind, body, and spirit.</p>
                </div>
                <div className="w-full flex flex-wrap p-4 justify-center">
                    { moduleList?.map((module: any) => (
                        <div key={module?.id} className="w-full md:w-6/12 lg:w-4/12 xl:w-3/12 p-2 md:p-4">
                            <div onClick={ () => handleModuleClick(module?.id) } className="p-4 rounded-xl bg-white ring-2 ring-gray-100 cursor-pointer transition duration-200 ease-linear hover:shadow-xl hover:-translate-y-5">
                                <div className="h-[170px] w-full overflow-hidden rounded-lg object-center">
                                    <img src={baseUrl + module?.imageurl} alt={module?.image} />
                                </div>
                                <div className="py-3">
                                    <h2 className="font-bold text-[18px] text-slate-800 line-clamp-1">{module?.name}</h2>
                                    <p className="text-sm text-gray-500 line-clamp-2">{module?.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ModuleSection;