
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../hooks/UseAppContext";
import { useUser } from "../../hooks/UseUser";
import UserAccountMenu from "../../ui-components/UserAccountMenu";

const Header = () => {
    const [showModuleSubmenu, setShowModuleSubmenu] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
    const dopdownMenuRef = useRef<any>(null);
    const { moduleList } = useAppContext();
    const userMenuRef = useRef<any>(null);
    const userAvatarRef = useRef<any>(null);
    const { userDetails  } = useUser();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleModuleClick = (e: any) => {
        e.stopPropagation();
        setShowModuleSubmenu(!showModuleSubmenu);
    }

    const handleUserMenu = (event: any) => {
        setShowUserMenu(!showUserMenu);
    }
    
    useEffect(() => {
        function handleClickOutside(event: any) {
            if ((dopdownMenuRef.current && !dopdownMenuRef.current.contains(event.target))) {
                setShowModuleSubmenu(false);
            }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleModuleSubMenuClick = (id: any) => {
        navigate('/product/'+id);
        setShowModuleSubmenu(false);
        setIsMobileMenuOpen(false);
    }

    const menuNavigate  = (menu: any) => {
        navigate(menu);
        setIsMobileMenuOpen(false);
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if ((userMenuRef.current && !userMenuRef.current.contains(event.target)) && (userAvatarRef.current && !userAvatarRef.current.contains(event.target)) ) {
                setShowUserMenu(false);
            }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <nav className="bg-white border-b border-gray-200 fixed w-full h-[77px] z-10">
                <div className="max-w-[1400px] flex items-center justify-between mx-auto px-4 py-5">
                    <div className="flex items-center">
                        <div className="md:hidden me-2 cursor-pointer" onClick={toggleMobileMenu}>
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </div>
                        <Link to="/" className="flex items-center space-x-3">
                            <div><span className='text-[26px] font-[700] text-primary'>Meridian</span><span className='text-[24px] text-gray-600'>guru</span></div>
                        </Link>
                    </div>
                    {/* <div className="relative">
                        <i className="lni lni-search-alt absolute top-[12px] left-[10px] text-[18px] text-slate-400 font-bold"></i>
                        <input type="text" className="border-2 min-w-[300px] border-slate-300 px-3 py-2 ps-[32px] rounded-xl" placeholder="Search..." />
                    </div> */}
                    <div className="flex flex-row items-center space-x-12">
                        <ul className="md:flex md:items-center md:space-x-6 font-medium hidden">
                            <li>
                                <Link to="/home" className="text-[16px] text-black transition duration-200  hover:text-primary">Home</Link>
                            </li>
                            <li className="relative" ref={dopdownMenuRef}>
                                <a className="text-[16px] flex cursor-pointer text-black transition duration-200 hover:text-primary items-center" onClick={(e) => handleModuleClick(e)}>
                                    Modules
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="ms-1 w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </a>
                                { (showModuleSubmenu && moduleList?.length > 0) &&
                                    (<div  className="absolute left-[-20px] top-[50px] shadow-xl bg-white rounded-[8px] ring-2 ring-slate-100 px-3 py-2 w-[250px]">
                                        <ul>
                                            { moduleList?.map((module: any) => (
                                                <li key={module?.id} onClick={() => handleModuleSubMenuClick(module?.id) } className="text-[16px] px-2 py-2 rounded-md cursor-pointer text-black transition duration-200 hover:text-primary hover:bg-slate-100">{module?.name}</li>
                                            )) }
                                        </ul>
                                    </div>)
                                }
                            </li>
                            <li>
                                <Link to="/about" className="text-[16px] text-black transition duration-200 hover:text-primary">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact" className="text-[16px] text-black transition duration-200 hover:text-primary">Contact Us</Link>
                            </li>
                        </ul>
                        <div className="ms-10">
                            {userDetails ? (
                                <div ref={userAvatarRef} onClick={ (event) => { handleUserMenu(event) } } className="cursor-pointer group flex flex-row items-center">
                                    <div className={`cursor-pointer bg-zinc-200 text-gray-500 ring-offset-2 group-hover:ring-2 group-hover:text-primary group-hover:bg-blue-50 ring-primary transition duration-300 uppercase w-[40px] h-[40px] rounded-full font-medium flex items-center justify-center text-[18px] ${showUserMenu? 'ring-2 text-primary bg-blue-50':''}`}>
                                        {userDetails?.name?.slice(0,1)}
                                    </div>
                                    <div className="ms-3 hidden md:block">
                                        <p className="m-0 font-semibold group-hover:text-primary">{userDetails?.name}</p>
                                        <p className="m-0 text-sm">{userDetails?.email}</p>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex items-center space-x-6">
                                    <Link to="/login" className="text-[16px] text-primary font-medium hover:text-primary">Login</Link>
                                    <Link to="/signup" className="text-[14px] bg-primary text-white rounded-md px-6 py-2 font-medium transition duration-200 hover:bg-blue-600">Sign Up</Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div ref={userMenuRef}> {showUserMenu && (<UserAccountMenu/>)} </div>
            </nav>
            {isMobileMenuOpen && (<section className="bg-white w-full h-full fixed z-10 md:hidden">
                <div className="py-2">
                    <div className="flex justify-between items-center border-dashed border-b-2 border-slate-200 px-2 py-3">
                        <div onClick={() => menuNavigate('/')} className="flex items-center space-x-3">
                            <div><span className='text-[26px] font-[700] text-primary'>Meridian</span><span className='text-[24px] text-gray-600'>guru</span></div>
                        </div>
                        <div className="cursor-pointer" onClick={toggleMobileMenu}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                    <ul className="flex flex-col space-y-5 py-3 px-3">
                        <li><div onClick={() => menuNavigate('/home')} className="text-[16px] text-black transition duration-200 hover:text-primary">Home</div></li>
                        <li ref={dopdownMenuRef}>
                            <a className="text-[16px] flex cursor-pointer text-black transition duration-200 hover:text-primary items-center" onClick={(e) => handleModuleClick(e)}>
                                Modules
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="ms-1 w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                            </a>
                            { (showModuleSubmenu && moduleList?.length > 0) &&
                                (<div  className="px-3 py-2">
                                    <ul>
                                        { moduleList?.map((module: any) => (
                                            <li key={module?.id} onClick={() => handleModuleSubMenuClick(module?.id) } className="text-[16px] px-2 py-2 rounded-md cursor-pointer text-black transition duration-200 hover:text-primary hover:bg-slate-100">{module?.name}</li>
                                        )) }
                                    </ul>
                                </div>)
                            }
                        </li>
                        <li><div onClick={() => menuNavigate('/about')} className="text-[16px] text-black transition duration-200 hover:text-primary">About Us</div></li>
                        <li><div onClick={() => menuNavigate('/contact')} className="text-[16px] text-black transition duration-200 hover:text-primary">Contact Us</div></li>
                    </ul>
                </div>
            </section>)}
        </>

    )
}

export default Header;