import React, { useEffect, useState } from "react";
import AccessDeniedImg from '../../assets/images/access_denied_img.jpg'
import ProductSlider from "./ProductSlider";
import { Link, useParams } from "react-router-dom";
import { AppService } from "../../services/AppService";
import Loader from "../../ui-components/Loader";
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";

const ProductDetails = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [productDetails, setProductDetails] = useState<any>(undefined);
  const [productId, setProductId] = useState<any>(undefined);
  let params = useParams();
  
  useEffect(() => {
    fetchProductDetails(params.id);
    setProductId(params.id);
  }, [params.id]);

  async function fetchProductDetails(id: any) {
    try {
      setLoader(true);
      const response = await AppService.fetchProductDetails(id);
      if(response?.status == 200){
        setLoader(false);
        setProductDetails(response?.data?.data || undefined);
      } else{
        setLoader(false);
        setProductDetails(undefined);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  }

  async function handelProductRequest() {
    try {
      setLoader(true);
      let data = { product_id:  productId };
      const response = await AppService.requestProduct(data);
      if(response?.status == 200){
        toast.success(messages?.product?.requestSuccess);
        fetchProductDetails(productId);
        setLoader(false);
      } else{
        setLoader(false);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  }

  return (
   <>
   {( productDetails?.status !== 'ACCESS_REQUESTED' && productDetails?.status !== 'ACCESS_DENIED') ?
      (<section className="p-6 py-[30px] w-full flex justify-center">
        <div className="max-w-[1400px] w-full lg:px-10 flex flex-row flex-wrap">
          <div className="w-full flex flex-row flex-wrap mb-5 font-semibold text-slate-700">
            <div className="cursor-pointer">Home </div> <span className="mx-1">{'>'}</span>
              <Link to={'/product/'+productDetails?.module_id} className="cursor-pointer">{productDetails?.module_name} </Link> <span className="mx-1">{'>'}</span>
              <Link to={'/product/'+productDetails?.module_id} className="cursor-pointer">{productDetails?.category_name} </Link> <span className="mx-1">{'>'}</span>
              <Link to={'/product/'+productDetails?.module_id} className="cursor-pointer">{productDetails?.sub_category_name} </Link> <span className="mx-1">{'>'}</span>
            <div className="text-primary">{productDetails?.name}</div>
          </div>
          {productDetails?.images?.length > 0 &&
            <div className="w-full lg:w-4/12">
              <ProductSlider images={productDetails?.images}/>
            </div>
          }
          
          <div className={`${productDetails?.images?.length > 0? 'w-full lg:w-8/12' : 'w-full'} lg:px-5`}>
              <div className="px-6 py-5 bg-white rounded-lg ring-1 ring-slate-200">
                  <div className="mb-4 pb-3 border-b border-dashed border-slate-200">
                      <h2 className="font-semibold text-[24px] text-black">{productDetails?.name}</h2>
                  </div>
                  <div className="text-gray-700 product-details">
                      <div dangerouslySetInnerHTML={{__html: productDetails?.content}}></div>
                  </div>
              </div>
          </div>
        </div>
      </section>) : 
      (<section className="p-6 py-[30px] w-full flex justify-center">
        <div className="max-w-[1400px] w-full px-10 flex flex-col justify-cnter items-center py-20">
          <img className="w-[260px]" src={ AccessDeniedImg } alt="Unlock Premium Access" />
          <h2 className="text-black font-bold text-center text-[30px] mb-2">Unlock Premium Access</h2>
          {(productDetails?.status === 'ACCESS_DENIED') ? 
            (<p className="text-slate-500 font-medium text-center text-[16px] w-[700px] mb-3">Access to this product is exclusive to premium members. Make a request for access to this content and enjoy all the benefits while unlocking access to premium content.</p>) : 
            (<p className="text-slate-500 font-medium text-center text-[16px] w-[700px] mb-3">Your request for access has already been submitted. Sit tight and we'll notify you once your request has been processed. Thank you for your patience!</p>)
          }
             <p className="text-slate-700 font-semibold text-center text-[18px] w-[700px]">Call On: 9090909090</p>
             <p className="text-slate-700 font-semibold text-center text-[18px] w-[700px] mb-3">Mail On: support@meridian.com</p>
          {(productDetails?.status === 'ACCESS_DENIED') && <button onClick={ handelProductRequest } className="px-10 py-2 bg-primary h text-white rounded-md transition duration-200 hover:bg-blue-600">Request Access</button>}
        </div>
      </section>)
   }
    {loader && <Loader/>}
   </>
  );
};

export default ProductDetails;
