

import React, { createContext, useEffect, useState } from 'react';
import { AppService } from '../services/AppService';

export const AppContext = createContext<any>(undefined);

export const AppProvider: React.FC<any> = ({ children }) => {
  const [moduleList, setModuleList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [subCategoryList, setSubCategoryList] = useState<any>([]);
  const [filteredSubCategoryList, setFilteredSubCategoryList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(undefined);
  const [selectedSubCategories, setSelectedSubCategories] = useState<any[]>([]);
  const [selectedSubCategoriesForFilter, setSelectedSubCategoriesForFilter] = useState<any[]>([]);
  const [productList, setProductList] = useState([]);
  const [moduleId, setModuleId] = useState(undefined);
  const [pageSize, setPageSize] = useState<any>(20);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [pageNo, setPageNo] = useState<any>(1);
  const [payStatus, setPayStatus] = useState<any>('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchModules();    
    fetchSubCategory();
  }, []);

  useEffect(() => {
    fetchCategory();
  },[moduleId])

  function handleSelectCategory(categoryId: any){
    setSelectedCategory(categoryId)
  }

  function handlePayStatusChange(value: any){
    setPayStatus(value)
  }

  function handleSearch(value: any){
    setSearchTerm(value)
  }

  function handleSelectSubCategories(subCategory: any) {
    setSelectedSubCategories((prevalue: any) => {
        if (subCategory.checked) {
            if (!prevalue.includes(subCategory.value)) {
                return [...prevalue, subCategory.value];
            }
        } else {
            return prevalue.filter((value: any) => value !== subCategory.value);
        }
        return prevalue;
    });
  }

  async function fetchModules() {
    try {
      const response = await AppService.fetchModules();
      if(response?.status == 200){
        setModuleList(response?.data?.data || []);
      }else{
        setModuleList([]);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  }

  async function fetchCategory() {
    try {
      if(moduleId){
        const response = await AppService.fetchCategory(moduleId);
        if(response?.status == 200){
          setCategoryList(response?.data?.data || []);
        }else{
          setCategoryList([]);
        }
      }else{
        setCategoryList([]);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  }

  async function fetchSubCategory() {
    try {
      const response = await AppService.fetchSubCategory();
      if(response?.status == 200){
        setSubCategoryList(response?.data?.data || []);
      }else{
        setSubCategoryList([]);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  }

  const handelModuleId = (id: any) => {
    setModuleId(id);
  }

  const handlePageNoChange = (page: any) => {
    setPageNo(page)
  }

  useEffect(() => {
    fetchProducts();
  },[moduleId, pageNo, selectedSubCategoriesForFilter, payStatus, searchTerm])

  async function fetchProducts() {
    try {
      if(moduleId){
        setLoader(true);
        let subCtaegoryIds = subCategoryList?.filter((cate: any) =>  selectedSubCategories?.includes(cate?.id) );
        subCtaegoryIds  = subCtaegoryIds?.map((el: any) => el.id);
        const response = await AppService.fetchProducts(moduleId, pageNo, pageSize, subCtaegoryIds, payStatus, searchTerm);
        if(response?.status == 200){
          let userDetails: any = localStorage.getItem('userdetails');
          userDetails = userDetails ? JSON.parse(userDetails) : {};
          // console.log('userDetails', userDetails)
          if(userDetails?.name){
            const userProductListresponse = await AppService.fetchUserProductList();
            if(userProductListresponse?.status == 200){
              let productIds = userProductListresponse?.data?.data?.map((el: any) => el.id);
              let modifiedData = response?.data?.data?.content?.map((el: any) => {
                if(productIds?.includes(el?.id)){
                  return { ...el, purchased: true}
                }else{
                  return { ...el, purchased: false};
                }
              })
              setTimeout(() => {
                setLoader(false);
              }, 2000);
              // console.log(modifiedData)
              setProductList(modifiedData || []);
              setTotalCount(response?.data?.data?.totalCount || 0);
            }else{
              setTimeout(() => {
                setLoader(false);
              }, 2000);
              setProductList(response?.data?.data?.content || []);
              setTotalCount(response?.data?.data?.totalCount || 0);
            }
          }else{
            setTimeout(() => {
              setLoader(false);
            }, 2000);
            setProductList(response?.data?.data?.content || []);
            setTotalCount(response?.data?.data?.totalCount || 0);
          }
        }else{
          setLoader(false);
          setProductList([]);
          setTotalCount(0);
        }
      }      
    } catch (error) {
      setLoader(false);
      console.error('Error fetching products:', error);
    }
  }

  useEffect(() => {
    handleFilterCategory(selectedCategory);
  }, [selectedCategory])

  const handleFilterCategory = (id: any) => {
    if(id){
      setFilteredSubCategoryList(subCategoryList?.filter((cate: any) => cate?.category_id == id));
    }
  }

  const handleApplyFilter = () => {
    setSelectedSubCategoriesForFilter((prevalue: any) => {
      return subCategoryList?.filter((cate: any) =>  selectedSubCategories?.includes(cate?.id) );
    });
  }

  const handleRemoveFilter = (id: any) => {
    setSelectedSubCategories((prevalue: any) => {
      return selectedSubCategories?.filter((cate: any) =>  cate != id );
    });

    setSelectedSubCategoriesForFilter((prevalue: any) => {
      return selectedSubCategoriesForFilter?.filter((cate: any) =>  cate?.id != id );
    });
  }

  const resetFilter = () => {
    setSelectedSubCategoriesForFilter([]);
    setSelectedSubCategories([]);
    setSelectedCategory('');
  }


  return (
    <AppContext.Provider value={{moduleList, categoryList, filteredSubCategoryList, selectedCategory, handleSelectCategory, selectedSubCategories, handleSelectSubCategories, resetFilter, selectedSubCategoriesForFilter, handleApplyFilter, handleRemoveFilter, handelModuleId, productList, handlePageNoChange, totalCount, pageSize, handlePayStatusChange, handleSearch, loader}}>
      {children}
    </AppContext.Provider>
  );
};