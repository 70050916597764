import React from "react";
import AboutBannerBg from '../../assets/images/about-banner-bg.jpg'
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/UseUser";

const AbountUsBanner = () => {
    const { userDetails  } = useUser();
    
    return(
        <div className="bg-white p-6 w-full">
            <div className="max-w-[1400px] h-[400px] bg-sky-700 border rounded-[50px] flex items-center justify-between mx-auto">
                <div className="w-full md:w-1/2 py-3 px-10">
                    {/* <p className="text-slate-200 text-lg mb-3">The Leader in Online Learning</p> */}
                    <h1 className="font-semibold text-white text-[30px] md:text-[40px] leading-[45px] mb-2">About Us</h1>
                    <div className="border-b-[6px] border-secondary w-[30px] mb-3"></div>
                    <p className="text-slate-200 text-lg mb-3">Welcome to MeridianGuru, where we empower personal growth through holistic healing. Discover transformative modalities tailored to reconnect you with your inner self</p>
                    {!userDetails && <div className="flex items-center space-x-3 mt-6">
                        <Link to="/login" className="text-[16px] bg-transparent border-2 border-white text-white rounded-md px-6 py-2 font-medium transition duration-300 hover:text-secondary hover:bg-slate-50 transition duration-150 ease-in">Login</Link>
                        <Link to="/signup" className="text-[16px] bg-secondary text-white border-2 border-secondary rounded-md px-6 py-2 font-medium transition duration-200">Sign Up</Link>
                    </div>}
                </div>
                <div className="w-1/2 hidden md:flex md:justify-end">
                    <img src={AboutBannerBg} alt="" className="h-[398px] grayscale rounded-tr-[50px] rounded-br-[50px]" />
                </div>
            </div>
        </div>
    )
}

export default AbountUsBanner;