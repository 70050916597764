import React from "react";
import AbountUsBanner from "./AbountUsBanner";
import AppoinmentSection from "../../ui-components/AppoinmentSection";
import HomeAboutSection from "../home/HomeAboutSection";
import { aboutUsKeyFeatures } from "../../utils/constants";

const AboutUs = () => {
  const aboutUsFeatures: any[] = aboutUsKeyFeatures;

  return (
    <section>
      <AbountUsBanner />
      <div className="bg-slate-100 about-bg p-6 w-full my-[50px] flex justify-center">
        <div className="max-w-[1400px] px-3 md:px-10 py-[35px]">
          <h2 className="font-bold text-[32px]">About Us</h2>
          <div className="border-b-[6px] border-primary w-[30px] mb-3"></div>
          <p className="font-medium text-gray-600">
              Step into a haven of healing at Anita Parwani's Alternative Therapy Solution.
              <br></br> Our place is all about giving you top-notch care and support as you journey towards wellness.  
              <br></br>Treatments made just for you, given by skilled experts who truly care about your well-being. We mix ancient wisdom with modern methods to fix everything - your body, emotions, and energy - for complete healing.
              <br></br> Our goal is to help you discover yourself and transform into the best version of you and we're all about making you feel safe and supported while you do it. With our special tools, we can find out exactly what's off-balance in your body and make a plan just for you. So, come join us on this journey to wellness and see for yourself the amazing ways holistic healing can change your life.
          </p>
          <div className="mt-10 flex flex-row flex-wrap">
            {aboutUsFeatures?.map((feature: any, index: any) => (
              <div key={index} className="w-full md:w-6/12 flex flex-row items-center mb-4">
                  <div className="bg-primary rounded-lg w-fit min-w-[50px] w-[50px] h-[50px] flex justify-center items-center me-4">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 stroke-white">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                      </svg>
                  </div>
                  <div>
                      <p className="font-semibold">{feature?.title}</p>
                      <p className="text-sm font-medium text-gray-500">{feature?.description}</p>
                  </div>
              </div>)
            )}
          </div>
        </div>
      </div>
      <HomeAboutSection />
      <AppoinmentSection />
    </section>
  );
};

export default AboutUs;
