import React from "react";
import acupressure from '../../assets/images/acupressure.jpg'

const HomeAboutSection = () => {
  return (
    <div className="bg-white p-6 w-full my-[15px] md:my-[50px] flex justify-center">
      <div className="max-w-[1400px] px-1 md:px-10 flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 py-3 px-1 md:px-10">
            <h2 className="font-semibold text-gray-700 text-[26px] mb-2">Who We Are</h2>
            <div className="border-b-[6px] border-secondary w-[30px] mb-3"></div>
            <div className="mb-4 text-gray-600">
                <p className="mb-2">We are a dedicated team of holistic healers committed to guiding you on your journey towards well-being and self-discovery. With a profound belief in the body's innate ability to heal itself, we combine ancient wisdom with modern techniques to offer personalized therapies that address your unique needs.</p>
                <p className="mb-2">At our core, we believe in treating the whole person - mind, body, and spirit. Our dedicated team of practitioners is committed to providing tailored solutions to address your unique needs, whether you seek relief from physical discomfort, emotional imbalances, or simply desire to enhance your overall well-being.</p>
                <p className="mb-2">With a diverse range of alternative therapies, including Sujok, Acupuncture, Aromatherapy, and crystal healing, we invite you to step into a realm where healing and self-discovery converge. Let us be your guide on this transformative journey towards empowerment and holistic wellness</p>
            </div>
            {/* <a href="/" className="text-primary font-medium flex items-center w-fit">Learn More <i className="lni lni-arrow-right ms-2"></i></a> */}
        </div>
        <div className="w-full lg:w-1/2 md:px-8 flex items-center">
            <div className="overflow-hidden w-full max-h-[340px] rounded-[15px] object-center">
              <img src={acupressure} alt="" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAboutSection;
