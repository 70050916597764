const Loader = (props: any) => {

    return (
        <div className="fixed backdrop-blur top-0 right-0 w-[100vw] h-[100vh] flex justify-center items-center z-[999] bg-slate-900/90 text-white">
            <div className="flex flex-col items-center">
                <svg className="animate-spin h-15 w-15" width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="3">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"/>
                            </path>
                        </g>
                    </g>
                </svg>
                <p className="mt-2 font-medium text-base">Loading...</p>
            </div>
        </div>
    )
}

export default Loader