import React, { useEffect, useState } from "react";
import ImgNotFound from '../../assets/images/broken-image.png'
import { environment } from "../../environment/environment";

const ProductCard = ({product, onClick}: any) => {
    const [imageError, setImageError] = useState(false);
    const baseUrl: any = environment.baseURL;

    const handleImageError = () => {
      setImageError(true);
    };

    useEffect(() => {
        setImageError(false);
    }, [product])

    const handleProductClick = (id: any) => {
        onClick(id);
    }

    return (
        <div onClick={ () => handleProductClick(product?.id) } className="p-4 rounded-xl bg-white ring-2 ring-gray-100 cursor-pointer">
            <div className="h-[170px] w-full overflow-hidden rounded-lg object-center ring-2 ring-gray-200">
                
                { (product?.pay_status == 2 || product?.purchased) ?
                ( 
                    <div className="relative">
                        { product?.purchased && <span className="absolute z-10 top-2 left-2 bg-blue-100 text-blue-600 text-sm font-medium px-2 rounded-lg">Purchased</span>}
                        {!imageError ? 
                            <img src={baseUrl + product?.product_images} alt={product?.name} onError={handleImageError}/> : 
                            <div className="flex flex-col justify-center items-center h-[170px] bg-gray-100">
                                {/* <img className="w-[80px]" src={ImgNotFound} alt={product?.name}/> */}
                                <div><span className='text-[26px] font-[700] text-gray-500'>Meridian</span><span className='text-[24px] text-gray-400'>guru</span></div>
                                {/* <p className="font-medium text-gray-500">Image not available</p> */}
                            </div>
                        }
                    </div>
                ) :
                ( <div className="relative h-[170px] product-bg">
                        <div className="absolute backdrop-blur-md z-1 flex flex-col justify-center items-center w-full h-[170px] bg-[#555555a3]">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 stroke-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                            <p className="text-white font-medium mt-3">This product is paid</p>
                        </div>
                    </div>)
                }
            </div>
            <div className="pt-3">
                <h2 className="font-semibold text-[16px] h-[50px] max-h-[50px] line-clamp-2">{product?.name}</h2>
                <p className="text-[14px] font-medium text-slate-600 line-clamp-1">{product?.category_name}</p>
            </div>
        </div>
    )
}

export default ProductCard;