import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../components/home/Home';
import Layout from '../components/layouts/Layout';
import ProductList from '../components/products/ProductList';
import ProductDetails from '../components/products/ProductDetails';
import AboutUs from '../components/about/AboutUs';
import Contact from '../components/contact/Contact';
import Login from '../components/authentication/Login';
import Signup from '../components/authentication/Signup';
import AuthGuard from '../guard/AuthGuard';
import PageNotFound from '../ui-components/PageNotFound';
import UserProducts from '../components/user/UserProducts';
import UserRequests from '../components/user/UserRequests';
import PrivacyPolicy from '../components/privacy-policy/PrivacyPolicy';


function NotFound() {
  return <PageNotFound/>;
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Layout><Home/></Layout>} />
      <Route path="/product/:id" element={<Layout><ProductList/></Layout>} />
      <Route path="/product-details/:id" element={<Layout><AuthGuard><ProductDetails/></AuthGuard></Layout>} />
      <Route path="/purchased-products" element={<Layout><AuthGuard><UserProducts/></AuthGuard></Layout>} />
      <Route path="/product-request" element={<Layout><AuthGuard><UserRequests/></AuthGuard></Layout>} />
      <Route path="/about" element={<Layout><AboutUs/></Layout>} />
      <Route path="/contact" element={<Layout><Contact/></Layout>} />
      <Route path="/login" element={<Layout><Login/></Layout>} />
      <Route path="/signup" element={<Layout><Signup/></Layout>} />
      <Route path="/privacy-policy" element={<Layout><PrivacyPolicy/></Layout>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
