import React, { useState } from "react";
import ContactBanner from "./ContactBanner";
import { useLocation } from "react-router-dom";
import { AppService } from "../../services/AppService";
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";
import Loader from "../../ui-components/Loader";

const Contact = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [name, setName] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [phoneNo, setPhoneNo] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    let errors: any = {};
    let isValid = true;

    if (!name.trim()) {
      errors.name = 'Name is required.';
      isValid = false;
    }

    if (!email.trim()) {
      errors.email = 'Email is required.';
      isValid = false;
    }else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email format.';
      isValid = false;
    } 

    if (!phoneNo) {
      errors.phoneNo = 'phone number is required.';
      isValid = false;
    }else if (!phoneRegex.test(phoneNo)) {
      errors.phoneNo = 'Invalid phone number.';
      isValid = false;
    }

    if (!message.trim()) {
      errors.message = 'Message is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { 
          name : name,
          phone_no: phoneNo,
          email: email,
          message: message
        };
        const response: any = await AppService.submitEnquiry(data);
        if (response?.status === 200) {
          toast.success(messages?.enquiry?.createdSuccess)
          setLoader(false);
          resetFrom();  
        } else {
          setLoader(false);
          toast.error(messages?.error);
        }
      } catch (error) {
        setLoader(false);
        toast.error(messages?.error);
      }
    }
  };

  const resetFrom = () => {
    setName('');
    setEmail('');
    setPhoneNo('');
    setMessage('');
  }

  return (
    <>
      <section>
        <ContactBanner />
        <div className="bg-white p-6 w-full my-[50px] flex justify-center">
          <div className="max-w-[1400px] w-full px-2 md:px-10 flex flex-col md:flex-row">
          <div className="w-full lg:w-5/12 py-3 md:px-10">
            <div className="h-full contact-form-bg rounded-[30px] bg-slate-100 flex flex-col justify-center items-center">
              <div className="bg-[#ffffff85] backdrop-blur-sm ring-2 ring-gray-100 py-10 px-4 lg:py-10 lg:px-10 rounded-[30px] flex flex-col justify-center items-center">
                <h2 className="font-bold text-[30px] mb-2">Contact Us</h2>
                <div className="flex flex-col justify-center items-center">
                  <div className="mb-2">
                    <i className="lni lni-phone text-primary font-semibold me-3"></i>
                    <span className="font-medium">77260-70755</span>
                  </div>
                  <div className="mb-2">
                    <i className="lni lni-envelope text-primary font-semibold me-3"></i>
                    <span className="font-medium">support@meridain.org</span>
                  </div>
                  {/* <div className="mb-2">
                    <i className="lni lni-map-marker text-primary font-semibold me-3"></i>
                    <span className="font-medium"> xyz, tehstheh, peun - 23233</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
            <div className="w-full lg:w-7/12 py-3 md:px-10">
              <form className="w-full">
                  <div className="form-control">
                      <label htmlFor="name">Name <span className="text-red-600">*</span></label>
                      <input type="text" value={name} onChange={(event) => { setName(event.target.value);}} placeholder="Enter your name" />
                      {errors?.name && <span className="text-red-600 text-sm ms-2 font-medium">{errors.name}</span>}
                  </div>
                  <div className="form-control">
                      <label htmlFor="email">Email <span className="text-red-600">*</span></label>
                      <input type="email" value={email} onChange={(event) => { setEmail(event.target.value);}}  placeholder="Enter your valid email" />
                      {errors?.email && <span className="text-red-600 text-sm ms-2 font-medium">{errors.email}</span>}
                  </div>
                  <div className="form-control">
                      <label htmlFor="phoneno">Phone no. <span className="text-red-600">*</span></label>
                      <input type="text" value={phoneNo} onChange={(event) => { setPhoneNo(event.target.value);}} placeholder="Enter your phone number" />
                      {errors?.phoneNo && <span className="text-red-600 text-sm ms-2 font-medium">{errors.phoneNo}</span>}
                  </div>
                  <div className="form-control">
                      <label htmlFor="phoneno">Message <span className="text-red-600">*</span></label>
                      <textarea name="message" value={message} onChange={(event) => { setMessage(event.target.value);}}  placeholder="Enter message..."  rows={5}></textarea>
                      {errors?.message && <span className="text-red-600 text-sm ms-2 font-medium">{errors.message}</span>}
                  </div>
                  <div className="mt-5">
                      <button onClick={ handleSubmit } className="text-[16px] bg-primary text-white rounded-md px-6 py-2 transition duration-200 hover:bg-blue-600" type="button">Send Message</button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {loader && <Loader/>}
    </>
  );
};

export default Contact;
