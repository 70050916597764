import React, { useState } from "react";
import acupressure from '../../assets/images/acupressure.jpg'
import { environment } from "../../environment/environment";

const ProductSlider = ({images}: any) => {
    const baseUrl: any = environment.baseURL;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const goToNextSlide = () => {
      setCurrentImageIndex((prevIndex: any) => (prevIndex + 1) % images?.length);
    };
  
    const goToPrevSlide = () => {
      setCurrentImageIndex((prevIndex: any) =>
        prevIndex === 0 ? images?.length - 1 : prevIndex - 1
      );
    };

    const handleImageSelection = (index: any) => {
        setCurrentImageIndex(index);
    }

    return(
        <div className="w-full">
            <div className="relative flex justify-center items-center">
                {images?.length > 1 && 
                <div onClick={goToPrevSlide} className="absolute bg-white rounded-full shadow-lg cursor-pointer p-2 left-[-10px] ring-1 ring-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                </div>}
                <div className="w-full h-[400px] bg-white rounded-md ring-1 ring-slate-200 overflow-hidden flex justify-center items-center">
                    <img className="max-w-[100%] max-h-[100%] w-[auto] h-[auto] rounded-sm" src={baseUrl + images[currentImageIndex]?.imageurl} alt={images[currentImageIndex]?.label} />
                </div>
                {images?.length > 1 && 
                <div onClick={goToNextSlide} className="absolute bg-white rounded-full shadow-lg cursor-pointer p-2 right-[-10px] ring-1 ring-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </div>}                
            </div>
            {images[currentImageIndex]?.method_id  &&
                <div className="ring-1 ring-slate-200 rounded-lg p-3 mt-3 mb-4">
                    <p className="font-semibold text-black">{images[currentImageIndex]?.method_name}</p>
                    <div className="w-10 border-[2px] border-primary mb-3"></div>
                    <div>
                    <p>{images[currentImageIndex]?.method_description}</p>
                    </div>
                </div>
            }            
            <div className="my-3 flex flex-row flex-wrap justify-normal gap-4">
                {images?.length > 1 && images?.map((img: any, index: any)=> (
                    <div key={index} onClick={(el) => handleImageSelection(index)} className="cursor-pointer w-[70px] h-[70px] bg-white rounded-md ring-1 ring-slate-200 overflow-hidden flex justify-center items-center">
                        <img className="object-cover w-full h-full rounded-sm" src={baseUrl + img?.imageurl} alt={img?.label} />
                    </div>
                ))}
                
            </div>
        </div>
    )
};

export default ProductSlider;