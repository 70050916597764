import React, { useState } from "react";
import PasswordVisibility from "../../ui-components/PasswordVisibility";
import { Link, useNavigate } from "react-router-dom";
import SignupBanner from "../../assets/images/signup-banner.jpg"
import { toast } from "react-toastify";
import { messages } from "../../utils/messages";
import { AuthService } from "../../services/AuthService";

const Signup = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState<any>({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const validateForm = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  
      let errors: any = {};
      let isValid = true;
  
      if (!name.trim()) {
        errors.name = 'Name is required.';
        isValid = false;
      }
  
      if (!email.trim()) {
        errors.email = 'Email is required.';
        isValid = false;
      }else if (!emailRegex.test(email)) {
        errors.email = 'Invalid email format.';
        isValid = false;
      } 
  
      if (!phoneNo) {
        errors.phoneNo = 'Phone no is required';
        isValid = false;
      }else if (!phoneRegex.test(phoneNo)) {
        errors.phoneNo = 'Invalid phone no.';
        isValid = false;
      } 
  
      if ( !password && !password.trim()) {
        errors.password = 'Password is required';
        isValid = false;
      } else if (!passwordRegex.test(password)) {
          errors.password = 'The password should be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number';
          isValid = false;
      }

      if (!confirmPassword.trim()) {
          errors.confirmPassword = 'Confirm password is required';
          isValid = false;
      } else if (confirmPassword.trim() !== password.trim()) {
          errors.confirmPassword = 'Passwords do not match';
          isValid = false;
      }
  
      setErrors(errors);
      return isValid;
    };
  
  
    const handleSubmit = async (event: any) => {
      event.preventDefault();
      if (validateForm()) {
        try { 
          setLoader(true);
          let data = { 
            name: name, 
            phone_no: phoneNo, 
            email: email, 
            password: password
          };

          const response: any = await AuthService.signup(data);
          if (response?.status === 200) {
            setLoader(false);
            toast.success(messages.user?.createdSuccess);
            navigate('/login');
          } else if (response?.status === 409) {
            setLoader(false);
            toast.error(response?.data?.message);
          } else {
            handleRequestError();
          }
        } catch (error: any) {
          setLoader(false);
          toast.error(error?.response?.data?.message);
        }
      }
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };
  
    const handleRequestError = () => {
      setLoader(false);
      toast.error(messages.error);
    };
  
    return (
      <section className="p-6 py-[40px] w-full flex justify-center">
        <div className="max-w-[1100px] w-full py-[30px]">        
          <div className="flex">
             <div className="md:w-1/2 hidden md:flex p-[20px] border-r-2">
                  <img src={SignupBanner} alt="SignupBanner" />
             </div>
             <div className="w-full md:w-1/2 md:px-20 py-5 flex flex-col justify-center">
                  <div className="mb-5">
                      <h2 className="font-bold text-[30px]">Sign Up</h2>
                      <div className="border-b-[6px] border-primary w-[30px] mb-3"></div>
                      <p className="text-slate-500 text-sm font-medium">Enter your details to create your account</p>
                  </div>
                  <form>
                        <div className="form-control">
                            <label htmlFor="user">Name</label>
                            <input type="text" placeholder="Enter your name" name='name' value={name} onChange={(event) => { setName(event.target.value);}} />
                            {errors?.name && <span className="text-red-600 text-sm ms-2 font-medium">{errors.name}</span>}
                        </div>
                        <div className="form-control">
                            <label htmlFor="user">Email</label>
                            <input type="email" placeholder="Enter your email" name='email' value={email} onChange={(event) => { setEmail(event.target.value);}} />
                            {errors?.email && <span className="text-red-600 text-sm ms-2 font-medium">{errors.email}</span>}
                        </div>
                        <div className="form-control">
                            <label htmlFor="user">Phone no.</label>
                            <input type="text" placeholder="Enter your number" name='phoneNo' value={phoneNo} onChange={(event) => { setPhoneNo(event.target.value);}} />
                            {errors?.phoneNo && <span className="text-red-600 text-sm ms-2 font-medium">{errors.phoneNo}</span>}
                        </div>
                        <div className="form-control">
                            <label htmlFor="user">Password</label>
                            <div className="input-group">
                                <input type={showPassword? 'text':'password'} placeholder="Enter password" name="password" value={password} onChange={(event) => { setPassword(event.target.value);}}/>
                                <PasswordVisibility visibility={showPassword} onClick={togglePasswordVisibility}/>
                            </div>
                            {errors?.password && <span className="text-red-600 text-sm ms-2 font-medium">{errors.password}</span>}
                        </div> 
                        <div className="form-control">
                            <label htmlFor="user">Confirm Password</label>
                            <div className="input-group">
                                <input type={showConfirmPassword? 'text':'password'} placeholder="Enter confirm password" name="confirmPassword" value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value);}}/>
                                <PasswordVisibility visibility={showConfirmPassword} onClick={toggleConfirmPasswordVisibility}/>
                            </div>
                            {errors?.confirmPassword && <span className="text-red-600 text-sm ms-2 font-medium">{errors.confirmPassword}</span>}
                        </div>                  
                        <div className='my-4'>
                            <button type="button" onClick={ handleSubmit } className="w-full text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5">Create Account</button>
                        </div>
                        <div className="mt-5 mb-3">
                            <p className="text-sm font-medium px-2">Already have an account? <Link to="/login" className="text-secondary cursor-pointer">Login</Link></p>
                        </div>
                  </form>
             </div>
          </div>
        </div>
      </section>
    );
};

export default Signup;