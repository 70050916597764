import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { AuthService } from "../services/AuthService";
import { Navigate } from "react-router-dom";
export const UserContext = createContext<any>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [userDetails, setUserDetails] = useState('');

    useEffect(()=>{
        getUserDetails();
    },[]);

    const getUserDetails = async () => {
        try { 
          const response: any = await AuthService.getUserDetails();
          if (response?.status === 200) {
            setUserDetails(response?.data?.data);
            localStorage.setItem('userdetails', JSON.stringify(response?.data?.data));
          } else {
            localStorage.clear();
            return <Navigate to="/login" replace />;
          }
        } catch (error) {
          localStorage.clear();
        }
      }
  
    return (
      <UserContext.Provider value={{ userDetails, getUserDetails }}>
        {children}
      </UserContext.Provider>
    );
  };

