import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="p-6 w-full my-[10px] flex justify-center">
        <div className="max-w-[1200px] px-10 py-[15px]">
            <h2 className="font-bold text-[30px] text-black">Privacy Policy:</h2>
            <div className="border-b-[6px] border-primary w-[30px] mb-3"></div>
            <p className="my-4">
                At Anita Parwani's Alternative Therapy Solution, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us through our website and during the course of our services.
            </p>
            <div className="my-4">
                <p className="font-semibold">Information We Collect:</p>
                <p>We may collect personal information, such as your name, contact details, medical history, and other relevant information, when you voluntarily provide it to us for the purpose of scheduling appointments, receiving services, or communicating with us.</p>
            </div>
            <div className="my-4">
                <p className="font-semibold">How We Use Your Information:</p>
                <p>We use the information we collect to provide you with personalized services, respond to your inquiries, schedule appointments, and communicate important updates or information related to our services. We may also use your information for internal purposes, such as improving our services and conducting research.</p>
            </div>
            <div className="my-4">
                <p className="font-semibold">Disclosure of Your Information:</p>
                <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law or as necessary to provide you with the requested services. We may share your information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you.</p>
            </div>
            <div className="my-4">
                <p className="font-semibold">Data Security:</p>
                <p>We implement a variety of security measures to maintain the safety of your personal information when you submit it to us. We use industry-standard encryption technology and secure server infrastructure to protect your data against unauthorized access, disclosure, alteration, or destruction.</p>
            </div>
            <div className="my-4">
                <p className="font-semibold">Your Consent:</p>
                <p>By using our website or providing us with your personal information, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>
            </div>
            <div className="my-4">
                <p className="font-semibold">Updates to this Privacy Policy:</p>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically for any updates or changes.</p>
            </div>
            <div className="my-4">
                <p className="font-semibold">Contact Us:</p>
                <p>If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at 77260-70755 or email us at support@meridianguru.org.</p>
            </div>
            <div className="my-4">
                <p>Thank you for trusting Anita Parwani's Alternative Therapy Solution with your personal information.</p>
            </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
