import axios from "axios";
import { toast } from "react-toastify";
import { environment } from "../environment/environment";

const http = axios.create({
    baseURL: environment.baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
});

// Request interceptor
http.interceptors.request.use(
    (config) => {  
      return config;
    },
    (error) => {
      console.error('Request error:', error);
      return Promise.reject(error);
    }
);

// Response interceptor
http.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response && error.response.status === 401) {
        await handleSessionExpired();
      } else {
        console.error('Response error:', error);
      }
      return Promise.reject(error);
    }
);

async function handleSessionExpired() {
    // toast.error('Your session has expired!')
    localStorage.clear();
    // window.location.href = '/login';
}

export default http;
