import React, { useEffect } from "react";
import { useAppContext } from "../hooks/UseAppContext";

const Checkbox = ({name, id, checked, onChange}: any) => {
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({checked: event.target.checked, value: Number(event.target.value)});
    };

    return(
        <div className="flex items-center mb-5">
            <input id={id} type="checkbox" value={id} checked={checked? true:false} onChange={handleCheckboxChange}
            className="form-checkbox h-4 w-4 min-w-4 text-secondary input:rounded-[10px]"/>
            <label htmlFor={id} className="ms-2 text-[15px] font-medium text-gray-500 cursor-pointer">{name}</label>
        </div>
    )
}

export default Checkbox;