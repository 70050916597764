export const faqList: any = [
    {
        question: "What is the purpose of mapping the acupressure meridian system at Anita Parwani's Alternative Therapy Solution?",
        answer: "Mapping the acupressure meridian system allows us to understand the body's energy pathways, aiding in precise diagnosis and treatment planning. This visual representation helps tailor therapies to address specific imbalances, promoting holistic healing."
    },
    {
        question: "What kind of support is available for understanding and utilizing the acupressure meridian system at Anita Parwani's Alternative Therapy Solution?",
        answer: "Our practitioners offer comprehensive training and guidance on utilizing the acupressure meridian system. Alongside user-friendly guides, we provide ongoing support to ensure practitioners feel confident in effectively utilizing this valuable tool for patient care."
    },
    {
        question: "How effective are the treatment protocols derived from mapping the acupressure meridian system?",
        answer: "The accuracy of treatment protocols depends on precise diagnosis. At Anita Parwani's Alternative Therapy Solution, our experienced practitioners utilize the mapped meridian system to develop highly effective, customized treatment plans tailored to each patient's unique needs for optimal results."
    },
    {
        question: "Is the acupressure meridian system accessible on mobile devices at Anita Parwani's Alternative Therapy Solution?",
        answer: "Yes, our holistic healing approach extends to mobile accessibility. You can access the mapped acupressure meridian system on both mobile and laptop devices, ensuring convenient and seamless access for practitioners wherever they may be."
    },
    {
        question: "How can I begin utilizing the acupressure meridian system at Anita Parwani's Alternative Therapy Solution?",
        answer: "To harness the benefits of the acupressure meridian system, simply reach out to us through the contact information provided on our website. Whether by phone, email, or through our contact form, our team is ready to assist you in incorporating this powerful tool into your holistic healing practice."
    }
]

export const aboutUsKeyFeatures: any[] = [
    {
        title: 'Personalized Treatments',
        description: 'Tailored therapies to suit your unique needs and preferences.'
    },
    {
        title: 'Experienced Practitioners',
        description: 'Skilled professionals dedicated to your holistic well-being.'
    },
    {
        title: 'Comprehensive Approach',
        description: 'Addressing physical, emotional, and energetic imbalances.'
    },
    {
        title: 'Ancient Wisdom, Modern Techniques',
        description: 'Blending traditional healing methods with contemporary practices.'
    },
    {
        title: 'Wellness Empowerment',
        description: 'Guiding you on a journey of self-discovery and transformation.'
    },
    {
        title: 'Diagnostic Precision',
        description: 'Pinpointing areas of imbalance for targeted treatment plans.'
    },
    {
        title: 'Holistic Focus',
        description: 'Treating the whole person – mind, body, and spirit – for lasting wellness.'
    },
    {
        title: 'Supportive Environment',
        description: 'Nurturing space where you feel empowered to explore your inner self.'
    }
];
