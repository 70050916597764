import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PasswordVisibility from "../../ui-components/PasswordVisibility";
import LoginBanner from "../../assets/images/login-banner.jpg"
import { toast } from "react-toastify";
import { AuthService } from "../../services/AuthService";
import { messages } from "../../utils/messages";
import { useUser } from "../../hooks/UseUser";

const Login = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [email, setEmail] = useState<any>('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { getUserDetails  } = useUser();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let prodcutParam = new URLSearchParams(location.search).get('productId');

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let errors: any = {};
    let isValid = true;

    if (!email.trim()) {
      errors.email = 'Email is required.';
      isValid = false;
    }else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email format.';
      isValid = false;
    } 

    if (!password.trim()) {
      errors.password = 'Password is required.';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      try { 
        setLoader(true);
        let data = { 
          username : email, 
          password : password
        };
        const response: any = await AuthService.login(data);
        if (response?.status === 200) {
          localStorage.setItem('access_token', response?.data?.data?.access_token);
          getUserDetails();
          setLoader(false);
          if(prodcutParam){
            navigate('/product-details/'+prodcutParam);
          }else{
            navigate('/home');
          }          
        } else {
          setLoader(false);
          toast.error(messages?.login?.invalidCreds);
        }
      } catch (error) {
        setLoader(false);
        toast.error(messages?.login?.invalidCreds);
      }
    }
  };

  return (
    <section className="p-6 py-[40px] w-full flex justify-center">
      <div className="max-w-[1100px] w-full py-[30px]">        
        <div className="flex md:flex-row flex-col">
           <div className="md:w-1/2 hidden md:flex p-[20px] border-r-2">
                <img src={LoginBanner} alt="LoginBanner" />
           </div>
           <div className="w-full md:w-1/2 md:px-20 py-5 flex flex-col justify-center">
                <div className="mb-5">
                    <h2 className="font-bold text-[30px]">Login</h2>
                    <div className="border-b-[6px] border-primary w-[30px] mb-3"></div>
                    <p className="text-slate-500 text-sm font-medium">Enter your email and password to access your account</p>
                </div>
                <form>                    
                    <div className="form-control">
                        <label htmlFor="user">Email</label>
                        <input type="email" placeholder="Enter your email" name='email' value={email} onChange={(event) => { setEmail(event.target.value);}} />
                        {errors?.email && <span className="text-red-600 text-sm ms-2 font-medium">{errors.email}</span>}
                    </div>
                    <div className="form-control">
                        <label htmlFor="user">Password</label>
                        <div className="input-group">
                            <input type={showPassword? 'text':'password'} placeholder="Enter password" name="password" value={password} onChange={(event) => { setPassword(event.target.value);}}/>
                            <PasswordVisibility visibility={showPassword} onClick={togglePasswordVisibility}/>
                        </div>
                        {errors?.password && <span className="text-red-600 text-sm ms-2 font-medium">{errors.password}</span>}
                    </div>                   
                    <div className='my-4'>
                        <button type="button" onClick={ handleLogin } className="w-full text-white bg-primary hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-8 py-2.5">Login</button>
                    </div>
                    <div className="mt-5 mb-3">
                        <p className="text-sm font-medium px-2">Don't have an account? <Link to="/signup" className="text-secondary cursor-pointer">Sign up</Link></p>
                        {/* <p className="text-sm font-medium text-secondary cursor-pointer px-2">Forgot Password?</p> */}
                    </div>
                </form>
           </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
