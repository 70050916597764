import React, { useEffect, useState } from "react";
import { AppService } from "../../services/AppService";
import ProductCard from "../products/ProductCard";
import { useNavigate } from "react-router-dom";
import NoDataFoundImg from "../../assets/images/no-data-found.png"

const UserProducts = () => {
    const [productList, setProductList] = useState([]); 
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts();
    },[])
    async function fetchProducts() {
        try {
            const response = await AppService.fetchUserProductList();
            if(response?.status == 200){
               let data = response?.data?.data?.map((el: any) => {
                return { ...el, pay_status: 2}
               })
              setProductList(data|| []);
            }else{
              setProductList([]);
            }
        } catch (error) {
          console.error('Error fetching products:', error);
        }
    }

    const handleProductClick = (id: any) => {
        const checkAccessToken = localStorage.getItem('access_token');
        if(checkAccessToken){
            navigate('/product-details/' + id);
        }else{
            navigate('/login?productId=' + id);
        }
        
    }

    return(
        <>
        <section className="p-6 py-[20px] w-full flex justify-center">
                <div className="max-w-[1400px] w-full px-2 md:px-10 flex flex-col">
                    <div className="w-full flex flex-col items-center justify-center">
                        <h2 className="font-bold text-center text-[30px]">Purchased Product List </h2>
                        <div className="border-b-[6px] border-secondary w-[30px] mb-3"></div>
                    </div>
                    <div className="flex flex-row">
                    { productList?.length > 0 ? 
                    <div className="w-full mb-10">
                        <div className="w-full flex flex-wrap">
                            {productList?.map((product: any, index: any) => (
                                <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-3" key={index}>
                                    <ProductCard product={product} onClick={(event: any) => handleProductClick(event)}/>
                                </div>
                            ))}
                            
                        </div>
                    </div> :
                    <div className="w-full flex flex-col justify-center items-center py-20">
                        <img className="w-[100px]" src={NoDataFoundImg} alt="NoDataFoundImg" />
                        <h2 className="text-black font-bold text-center text-[30px] mb-2">Data Not Found!</h2>
                        <p className="text-slate-500 font-medium text-center text-[16px] w-full md:w-[700px] mb-3">We couldn't find any data or there may be an issue.</p>
                    </div>
                    }
                    </div>
                </div>
            </section>
        </>
    )

}

export default UserProducts;