import React from "react";
import HomeBanner from "./HomeBanner";
import HomeAboutSection from "./HomeAboutSection";
import ModuleSection from "./ModuleSection";
import FaqSection from "../../ui-components/FaqSection";
import AppoinmentSection from "../../ui-components/AppoinmentSection";
import { Link } from "react-router-dom";
import { aboutUsKeyFeatures } from "../../utils/constants";


const Home = () => {
    const aboutUsFeatures: any[] = aboutUsKeyFeatures;

    return (
       <section>
            <HomeBanner/>
            <HomeAboutSection/>
            <ModuleSection/>
            <div className="bg-white p-2 md:p-6 w-full my-[50px] flex justify-center">
                <div className="max-w-[1400px] px-1 md:px-10 flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/2 py-3 px-1 md:px-10">
                        <h2 className="font-bold text-gray-700 text-xl md:text-[30px] mb-2">Journey to Wellness: Holistic Healing Experts</h2>
                        <div className="border-b-[6px] border-secondary w-[30px] mb-6"></div>
                        <div className="mt-8 mb-4 text-gray-600">
                            <p className="mb-2">Step into a haven of healing at Anita Parwani's Alternative Therapy Solution.
                            <br></br> Our place is all about giving you top-notch care and support as you journey towards wellness.  
                            <br></br>Treatments made just for you, given by skilled experts who truly care about your well-being. We mix ancient wisdom with modern methods to fix everything - your body, emotions, and energy - for complete healing.
                            <br></br> Our goal is to help you discover yourself and transform into the best version of you and we're all about making you feel safe and supported while you do it. With our special tools, we can find out exactly what's off-balance in your body and make a plan just for you. So, come join us on this journey to wellness and see for yourself the amazing ways holistic healing can change your life.</p>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 px-1 md:px-8 flex flex-col justify-center">
                        {aboutUsFeatures?.map((feature: any, index: any) => (
                            <div key={index} className="flex flex-row items-center mb-4">
                                <div className="bg-blue-50 rounded-lg w-fit min-w-[50px] w-[50px] h-[50px] flex justify-center items-center me-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 stroke-primary">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="font-semibold">{feature?.title}</p>
                                    <p className="text-sm text-gray-500">{feature?.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bg-white p-2 md:p-6 w-full my-[40px] flex justify-center">
                <div className="max-w-[1400px] w-full px-2 md:px-10 flex flex-col items-center justify-center bg-gradient-to-r from-emerald-600 to-secondary py-10 rounded-[25px]">
                    <h2 className="text-[22px] md:text-[36px] md:max-w-[70%] mb-4 font-medium md:font-bold text-white text-center">Are you ready for your transformative journey towards holistic wellness?</h2>
                    <p className="text-lg w-full md:max-w-[80%] text-gray-100 text-sm md:text-md md:font-medium text-center">Join us at Anita Parwani's Alternative Therapy Solution and experience the profound benefits of holistic healing. Our sanctuary of healing awaits, where personalized treatments, compassionate care, and expert guidance converge to support your well-being every step of the way</p>
                    <div className="flex items-center space-x-3 mt-6">
                        <Link to='/contact' className="text-[16px] bg-transparent border-2 border-white text-white rounded-md px-6 py-2 font-medium transition duration-300 hover:text-secondary hover:bg-slate-50 transition duration-150 ease-in">Join now</Link>
                    </div>
                </div>
            </div>
            <FaqSection/>
            <AppoinmentSection/>
       </section>
    )
}

export default Home;