import React, { useState } from "react";
import { useUser } from "../hooks/UseUser";
import EditProfile from "../components/user/EditProfile";
import { Link, useNavigate } from "react-router-dom";

const UserAccountMenu = () => {
    const { userDetails  } = useUser();
    const [editProfileModal, SetEditProfileModal] = useState<boolean>(false);
    const navigate = useNavigate();
    
    const logout = () => {
        localStorage.clear();
        window.location.href = '/login';
    }

    const handleEditProfileModal = (flag: boolean) => {
        SetEditProfileModal(flag);
    }

    const handleNavigation = (url: string) => {
        navigate(url);
    }

    return (
        <>
            <div className="absolute z-10 shadow-2xl right-[10px] top-[80px] ring-2 ring-gray-100 w-[300px] bg-white rounded-lg">
                <div className="flex flex-row my-3 p-3">
                    <div className='cursor-pointer bg-gray-100 text-primary  ring-2 ring-primary transition duration-300 uppercase w-[50px] h-[50px] rounded-full font-semibold flex items-center justify-center text-[23px]'>
                        {userDetails?.name?.slice(0,1)}
                    </div>
                    <div className="ms-3"> 
                        <p className="font-semibold">{userDetails?.name}</p>
                        <p className="text-slate-500">{userDetails?.email}</p>
                    </div>
                </div>
                <div className="my-2 w-full border-b-2 border-gray-200 border-dashed"></div>
                <div className="mb-4 mx-3">
                    <div onClick={ () => handleEditProfileModal(true) } className="px-3 py-2 cursor-pointer rounded-md text-gray-600 transition duration-300 font-medium hover:text-primary hover:bg-slate-50">Edit Profile</div>
                    <div onClick={() => handleNavigation('/purchased-products') } className="px-3 py-2 cursor-pointer rounded-md text-gray-600 transition duration-300 font-medium hover:text-primary hover:bg-slate-50">Purchased Products</div>
                    <div onClick={() => handleNavigation('/product-request') } className="px-3 py-2 cursor-pointer rounded-md text-gray-600 transition duration-300 font-medium hover:text-primary hover:bg-slate-50">Your Requests</div>
                    <div onClick={logout} className="px-3 py-2 cursor-pointer rounded-md text-gray-600 transition duration-300 font-medium hover:text-primary hover:bg-slate-50">Log Out</div>
                </div>
            </div>
            { editProfileModal && <EditProfile userData={userDetails} onClose={() => handleEditProfileModal(false)}/> }
        </>
    )
}

export default UserAccountMenu;